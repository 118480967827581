import './App.css';
import React from 'react';
import Home from './pages/Home';
import Projects from './pages/Projects';

enum AppPage { home, projects }

interface IState {
    page: AppPage;
}

class App extends React.Component<any, IState> {
    constructor(props: any) {
        super(props);

        this.state = { 
            page: AppPage.home
        }

        if (window.location.href.includes("/projects")) {
            this.state = { 
                page: AppPage.projects
            }
        }
    }

    render() {
        if (this.state.page === AppPage.home) {
            return (
                <Home />
            )
        } else {
            return (
                <Projects />
            )
        }
    }
}

export default App;
