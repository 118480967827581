import React from "react";
import { SocialIcon } from "react-social-icons";
import photo from '../assets/me.jpg';
import mastodon from "../utils/mastodon_logo";

class Home extends React.Component {

    render() {
        return (
            <div className="App">
                <nav>
                    <a href="/projects">projects</a>
                </nav>
                <header className="App-header">
                    <img src={photo} className="App-logo" alt="logo" />
                    <h1>Liam Edwards</h1>
                    <p>
                        Mobile Developer at&nbsp;
                        <a className="App-link" target="_blank" href="https://services.snapper.co.nz">Snapper</a>
                        &nbsp;for the <a className="App-link" target="_blank" href="https://mosaiq.co">Mosaiq</a> suite.
                        <br />
                        Typically working in Jetpack Compose & SwiftUI.
                    </p>
                    <div className="social">
                        <SocialIcon
                            target="_blank"
                            rel="noopener noreferrer me"
                            url="https://mastodon.nz/@blowonthepi"
                            defaultSVG={mastodon} />

                        <SocialIcon
                            target="_blank"
                            rel="noopener noreferrer"
                            url="https://twitter.com/BlowOnThePi" />

                        <SocialIcon
                            target="_blank"
                            rel="noopener noreferrer"
                            url="https://linkedin.com/in/imliamedwards" />

                        <SocialIcon
                            target="_blank"
                            rel="noopener noreferrer"
                            url="https://github.com/blowonthepi"
                            fgColor="white"
                            bgColor="black" />

                        <SocialIcon
                            target="_blank"
                            rel="noopener noreferrer"
                            url="mailto:kiaora@liam.kiwi" />
                    </div>
                </header>
            </div>
        );
    }
}

export default Home